/*
 * Implementation of themes
 */
.paginate--component .pagination {
  justify-content: center;
  margin-top: 20px; }

.paginate--component .page-item {
  padding: 1px; }
  .paginate--component .page-item:first-child .page-link .sr-only:before {
    content: '<';
    padding-right: 5px; }
  .paginate--component .page-item:last-child .page-link .sr-only:after {
    content: '>';
    padding-left: 5px; }
  .theme-light .paginate--component .page-item.active .page-link {
    color: #FFF;
    border-color: #4D7CFE;
    background-color: #4D7CFE; }
  .theme-dark .paginate--component .page-item.active .page-link {
    color: #FFF;
    border-color: #4D7CFE;
    background-color: #4D7CFE; }
  .paginate--component .page-item.disabled .page-link {
    background-color: transparent;
    border: none; }
  .paginate--component .page-item .page-link {
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    background-color: transparent; }
    .theme-light .paginate--component .page-item .page-link {
      color: #000; }
    .theme-dark .paginate--component .page-item .page-link {
      color: #778CA2; }
    .paginate--component .page-item .page-link:focus {
      box-shadow: none; }
    .paginate--component .page-item .page-link:hover {
      color: #FFFFFF; }
      .theme-light .paginate--component .page-item .page-link:hover {
        background-color: #4D7CFE; }
      .theme-dark .paginate--component .page-item .page-link:hover {
        background-color: #4D7CFE; }
      .paginate--component .page-item .page-link:hover span.sr-only {
        color: #FFFFFF; }
    .paginate--component .page-item .page-link span {
      display: none; }
      .paginate--component .page-item .page-link span.sr-only {
        display: inline;
        position: relative;
        color: #778CA2; }
