/*
 * Implementation of themes
 */
.theme-dark .css-1rhbuit-multiValue {
  background: #000;
  border: 1px solid #2a2d2e; }
  .theme-dark .css-1rhbuit-multiValue .css-12jo7m5 {
    color: #778ca2; }
  .theme-dark .css-1rhbuit-multiValue .css-xb97g8:hover {
    background: #000; }
    .theme-dark .css-1rhbuit-multiValue .css-xb97g8:hover .css-tj5bde-Svg {
      color: #FFF; }

.theme-dark .react-tag-input {
  background: #000; }
  .theme-dark .react-tag-input .react-tag-input__tag {
    background: #000;
    border: 1px solid #e1e1e1;
    color: #e1e1e1; }
  .theme-dark .react-tag-input .react-tag-input__tag__remove {
    background: #1d1c1c; }
  .theme-dark .react-tag-input .react-tag-input__tag__remove:before, .theme-dark .react-tag-input .react-tag-input__tag__remove:after {
    color: #FFF; }

.ProjectsPage .TableRow .row-details {
  flex-grow: 2; }
  .ProjectsPage .TableRow .row-details.row-head-mobile {
    flex-grow: 3; }

.ProjectsPage .css-b62m3t-container {
  margin-top: 6px;
  font-size: 14px; }
  .ProjectsPage .css-b62m3t-container .css-14el2xx-placeholder {
    color: #000 !important; }
  .ProjectsPage .css-b62m3t-container .css-1okebmr-indicatorSeparator {
    display: none; }
  .ProjectsPage .css-b62m3t-container .css-tlfecz-indicatorContainer {
    color: #000; }
    .ProjectsPage .css-b62m3t-container .css-tlfecz-indicatorContainer svg {
      height: 15px; }
  .ProjectsPage .css-b62m3t-container input {
    height: 20px !important; }
