// Breakpoints here
$breakpoint-bigtablet: 1280px;
$breakpoint-tablet: 1024px;
$breakpoint-all-phone: 768px;
$breakpoint-small-mobile: 410px;

//sidebar width
$sidebarWidth: 242px;

$primaryDark: #252631;
$secondaryDark2: #98A9BC;
$orange: #FFAB2B;
$green: #6DD230;
$pink: #FE4D97;
$outlineGrey: #E8ECEF;
$backgroundGrey: #F2F4F6;
$backgroundGrey3: #ccc;
$white: #FFFFFF;
$darkBlue: #0069d9;
$darkBlue2: #2196F3;
$black: #000000;
$lightGrey: #778CA2;
$lightGrey2: #F8FAFB;
$lightBlue: #2CE5F6;
$lightYellow: #FFF9EE;
$lightOrange: #efd765;
$blue: #4d7cfe;
$middleGrey: #98a9bc;
$midGrey: #778ca2;
$whiterGrey: #fafbff;
$borderBlue: #e5ecff;

$themes: (
  light: (
    //general
    bodyBG: #F8FAFB,
    bodyColor: #212529,
    primaryBtnColor: #FFFFFF,
    primaryBtnBg: #4D7CFE,
    primaryBtnHoverBg: #0069d9,
    primaryBtnHoverColor: #FFFFFF,

    secondaryBtnBg: #E8ECEF,
    secondaryBtnColor: #778CA2,
    secondaryBtnHoverBg: #E8ECEF,
    secondaryBtnHoverColor: #4D7CFE,

    headerBg: #FFFFFF,
    mainAreaBorders: #E8ECEF,

    overviewCardBg: #FFFFFF,
    overviewCardColor: #252631,
    overviewCardTitleColor: #000,
    overviewMask: #4D7CFE,

    eventContainerBg: #FFF,
    eventText: #252631,
    eventUserBorder: #E8ECEF,

    sidebarBg: #FFFFFF,
    sidebarItemColor: #778CA2,
    sidebarItemHover: #F8FAFB,

    tableRowBg: #FFF,
    tableRowChildBg: #ececec,
    tableRowHover: #F2F4F6,
    tableRowValueColor: #252631,

    projectSubsectionBg: #FFF,
    projectSubsectionBorder: #E8ECEF,

    dropdownBg: #FFF,
    inputBorder: #d3d3d3,
    inputBg: #FFF,
    inputColor: #000,
    dropdownLink: #000,
    ddBtnColor: #000,
    ddBtnBg: #FFF,
    ddBtnHoverColor: #000,
    ddBtnHoverBg: #f8f9fa,

    noDataText: #98A9BC,
    pageLinkColor: #000,
    pageLinkActiveColor: #FFF,
    pageLinkActiveBorder: #4D7CFE,
    pageLinkActiveBg: #4D7CFE,

    overlayBg: #000,
    modalBg: #F8FAFB,
    modalHeaderBg: #FFF,
    modalLabel: #252631,
    modalInputBg: #FFF,
    modalInputColor: #000,

    datepickerBg: #FFF,
    datepickerDayBg: #FFF,
    datepickerDayColor: #000,
    chkInputBg: #eee,
    devOutter: #FFF,
    suggestionsBg: #FFF,
    suggestionsBorder: #E8ECEF,

    invoiceTotalColor: #000
  ),
  dark: (
    bodyBG: #0b0b0b,
    bodyColor: #778ca2,
    primaryBtnColor: #000,
    primaryBtnBg: #5080fa,
    primaryBtnHoverBg: #0069d9,
    primaryBtnHoverColor: #000,

    secondaryBtnBg: #1c1d1e,
    secondaryBtnColor: #657587,
    secondaryBtnHoverBg: #0d0e0e,
    secondaryBtnHoverColor: #586573,

    headerBg: #000000,
    mainAreaBorders: #1a1c1c,

    overviewCardBg: #000000,
    overviewCardColor: #5f6c79,
    overviewCardTitleColor: #FFF,
    overviewMask: #4D7CFE,

    eventContainerBg: #000,
    eventText: #778ca2,
    eventUserBorder: #1a1c1c,

    sidebarBg: #000000,
    sidebarItemColor: #778CA2,
    sidebarItemHover: #0b0b0b,

    tableRowBg: #000,
    tableRowChildBg: #28250a,
    tableRowHover: #1a1a1a,
    tableRowValueColor: #778ca2,

    projectSubsectionBg: #000,
    projectSubsectionBorder: #1a1c1c,

    dropdownBg: #272727,
    inputBorder: #2a2d2e,
    inputBg: #000,
    inputColor: #FFF,
    dropdownLink: #778ca2,
    ddBtnColor: #778ca2,
    ddBtnBg: #202223,
    ddBtnHoverColor: #f8f9fa,
    ddBtnHoverBg: #1a1b1c,

    noDataText: #98A9BC,
    pageLinkColor: #778CA2,
    pageLinkActiveColor: #FFF,
    pageLinkActiveBorder: #4D7CFE,
    pageLinkActiveBg: #4D7CFE,

    overlayBg: #888888,
    modalBg: #0b0b0b,
    modalHeaderBg: #000,
    modalLabel: #778ca2,
    modalInputBg: #000,
    modalInputColor: #FFF,

    datepickerBg: #000,
    datepickerDayBg: #0b0b0b,
    datepickerDayColor: #778ca2,
    chkInputBg: #778ca2,
    devOutter: #000,
    suggestionsBg: #000,
    suggestionsBorder: #2a2d2e,

    invoiceTotalColor: #FFF
  )
);

/*
 * Implementation of themes
 */
 @mixin themify($themes) {
    @each $theme, $map in $themes {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }