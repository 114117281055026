@mixin background($src) {
	position:absolute;
	background:url($src) no-repeat;
	background-size:contain;
	display:inline-block;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin transition($transition) {
	-webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin box-shadow($boxShadow) {
	-webkit-box-shadow: $boxShadow;
	-moz-box-shadow: $boxShadow;
	box-shadow: $boxShadow;
}

@mixin btn() {
  display: inline-block;
}

@mixin btn-circle() {
  @include btn();
  border: 1px solid #FFFFFF;
  border-radius: 50%;
}
@mixin opacity($opac) {
    filter: alpha(opacity=$opac * 100);
    -ms-filter: alpha(opacity=$opac * 100);
    -moz-opacity: $opac;
    -webkit-opacity: $opac;
    opacity: $opac;
}

@mixin backface-visibility($visibility){
	-webkit-backface-visibility: $visibility;
	-moz-backface-visibility: $visibility;
	-ms-backface-visibility: $visibility;
	backface-visibility: $visibility;
}

@mixin filter($filter){
	-webkit-filter: $filter;
	-moz-filter: $filter;
	-o-filter: $filter;
	filter: $filter;
}

@mixin background-size($bgsize){
	-webkit-background-size: $bgsize;
	-moz-background-size: $bgsize;
	background-size: $bgsize;
}

@mixin transform($transform){
	-webkit-transform: $transform;
	-moz-transform: $transform;
	-o-transform: $transform;
	-ms-transform: $transform;
	transform: $transform;
}

@mixin transform-origin($top, $left){
	-webkit-transform-origin: $top $left;
	-moz-transform-origin: $top $left;
	-o-transform-origin: $top $left;
	-ms-transform-origin: $top $left;
	transform-origin: $top $left;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin keyframes($animation){
	@-moz-keyframes #{$animation} {@content}
	@-webkit-keyframes #{$animation} {@content}
	@-o-keyframes #{$animation} {@content}
	@-ms-keyframes #{$animation} {@content}
	@keyframes #{$animation} {@content}
}

@mixin animation($params){
	-webkit-animation: $params;
	-moz-animation: $params;
	-o-animation: $params;
	-ms-animation: $params;
	animation: $params;
}

@mixin centerer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}