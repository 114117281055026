.bg-dark {
  background-color: #000 !important;
  box-shadow: none;
}

.rmdp-day, .rmdp-week-day {
  color: #778ca2 !important;
}

.rmdp-header-values{
  color: #4d7cfe !important;
}