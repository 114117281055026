/*
 * Implementation of themes
 */
.DashboardPage {
  padding: 20px; }
  .DashboardPage .dash-section-title {
    font-size: 18px;
    line-height: 1;
    padding-left: 10px; }
  .DashboardPage .dash-events {
    width: 100%; }
  .DashboardPage .event-outter {
    margin-bottom: 20px; }
