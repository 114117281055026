/*
 * Implementation of themes
 */
.TSPage .export-link a {
  margin-right: 100px;
  color: #000;
  background-color: #5080fa;
  border-style: none;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  padding: 15px 30px;
  cursor: pointer;
  display: inline-block;
  float: right; }

.TSPage .ts-head {
  padding: 20px 0;
  height: 150px; }
  .TSPage .ts-head .ts-pagination {
    display: flex;
    justify-content: center;
    align-items: center; }
    .TSPage .ts-head .ts-pagination .btn-pag {
      background: transparent;
      color: #4d7cfe;
      font-size: 24px;
      line-height: 1;
      border: 0; }
    .TSPage .ts-head .ts-pagination span {
      font-size: 18px;
      line-height: 1;
      margin: 0 10px; }

.TSPage .daily-summary {
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  margin-bottom: 5px; }
  .TSPage .daily-summary time {
    color: #98a9bc; }

.TSPage .TableMainContainer {
  margin-bottom: 25px; }

.TSPage .ts-form {
  display: flex;
  justify-content: center;
  align-items: center; }
  .TSPage .ts-form .btn-new-container {
    margin: 0 0 20px 0;
    color: #4d7cfe;
    border: 0;
    background: #FFFFFF;
    padding: 10px 20px;
    border: 1px solid #F2F4F6;
    border-radius: 4px; }
    .TSPage .ts-form .btn-new-container i {
      margin-right: 5px; }
