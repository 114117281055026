
.form-group {
    input[type=text], input[type=password], .file-input-placeholder {
        height: 52px;
        border-radius: 2px;
        padding-left: 10px;
    }
    .react-autosuggest__suggestions-container[role="listbox"] {
        z-index: 999;
    }
    .file-input-placeholder {
        background: $white;
        height: 52px;
        position: relative;
        border-radius: 2px;
        border: 1px dotted $outlineGrey;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        padding-right: 60px;
        line-height: 1;
        color: $blue;

        i {
            margin-right: 5px;
            font-size: 20px;
            line-height: 1;
        }

        .form-control-file {
            opacity: 0;
            font-size: 100px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
    }

    .form-control {
        border-color: $outlineGrey;
        height: 52px;

        &.error {
            border-color: red;
        }
    }
    .custom-select {
        border-color: $outlineGrey;
        height: 52px;
    }
    .error-container{
        color: red;
        padding: 5px 0;
    }
    .react-datepicker__input-container {
        width: 100%;

        input {
            border: 1px solid $outlineGrey;
            width: 100%;
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input {
        border: 1px solid $outlineGrey;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 51px;
        width: 100%;
        border: 1px solid;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;

        @include themify($themes) {
            background-color: themed('suggestionsBg');
            border-color: themed('suggestionsBorder');
        }
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            font-size: 14px;
            transition: all .3s ease;
        }
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: $blue;
        color: $white;
    }

    .btn-del {
        position: absolute;
        right: 10px;
        z-index: 9;
        font-style: normal;
        cursor: pointer;
    }
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    background-color: $white;
    color: $blue;
}
.react-datepicker__header {
    background: $blue;
    color: $white;
}
.react-datepicker__current-month, .react-datepicker-time__header {
    color: $white;
}
.react-datepicker__day-name {
    color: $white;
}
.react-datepicker__day, .react-datepicker__day-name {
    width: 2.5rem;
    line-height: 2rem;
}
label {
    font-size: 14px;
    font-weight: 400;
    color: $primaryDark;
}

.form-error{
    color: red;
}

.error-container {
    .react-autosuggest__input{
        border-color: red;
    }
}

.chk-container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 20px;
    min-height: 20px;
    margin-bottom: 0;

    &:hover {
        input ~ .checkmark {
            background-color: $backgroundGrey3;
        }
        input:disabled ~ .checkmark {
            @include themify($themes) {
                background-color: themed('chkInputBg');
            }
        }
        input:checked ~ .checkmark {
            background-color: $darkBlue2;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ .checkmark {
                background-color: $darkBlue2;

                &:after{
                    display: block;
                }
                &:hover{
                    background-color: $darkBlue2;
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 2px;
        @include themify($themes) {
            background-color: themed('chkInputBg');
        }

        &:after{
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 3px;
            width: 6px;
            height: 11px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    &.rejected {
        input + .checkmark {
            background-color: $pink;
            &:before{
                width: 15px;
                height: 3px;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                content: '';
                display: block;
                background: $whiterGrey;
                top: 8px;
                left: 2.5px;
                position: absolute;
            }
            &:after{
                width: 15px;
                height: 2px;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
                top: 8px;
                left: 2.5px;
            }
        }
    }
}

// ###--- Buttons ---###

// Primary Button & Outline Button
.btn--ts {
    border-style: none;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    text-align: center;
    border-radius: 4px;
    padding: 15px 30px;
    margin: 10px;
    cursor: pointer;
    @include themify($themes) {
        color: themed('primaryBtnColor');
        background-color: themed('primaryBtnBg');
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    i {
        padding-right: 5px;
    }

    &-primary {
         &:hover {
             @include themify($themes) {
                 color: themed('primaryBtnHoverColor');
                 background-color: themed('primaryBtnHoverBg');
             }
         }

         &:active {
             @include themify($themes) {
                 color: themed('primaryBtnColor');
                 background-color: themed('primaryBtnBg');
             }
         }
     }

     &-secondary {
         padding: 6px 10px;
         margin: 6px 5px 8px;

         &:first-child {
             margin-left: 0;
         }
     }

    &-grey{
        border: none;
        @include themify($themes) {
            color: themed('secondaryBtnColor');
            background-color: themed('secondaryBtnBg');
        }

        &:hover {
            @include themify($themes) {
                color: themed('secondaryBtnHoverColor');
                background-color: themed('secondaryBtnHoverBg');
            }
        }

        &:active {
            @include themify($themes) {
                color: themed('secondaryBtnHoverColor');
                background-color: themed('secondaryBtnHoverBg');
            }
            box-shadow: none;
        }
    }
}

.tooltip-container {
    display: block;

    .icon {
        position: absolute;
        left: 40px;
        top: 5px;
    }

    .tooltip-element {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 20vw;
        height: 100%;
        z-index: 5;
        opacity: 0;
        transform: translateX(-50%);
        cursor: pointer;

        &:hover{
            opacity: 1;
            visibility: visible;
            padding-bottom: 100%;

            .tooltip-content {
                position: relative;
                display: block;
                bottom: 100%;
                padding: 15px;
                width: calc(100% + 60px);
                background-color: $whiterGrey;
                border-radius: 4px;
                font-size: 105%;
                left: -40px;
            }
        }
    }
}

textarea,
input{
    &::placeholder {
        font-size: 14px;
    }
    &::-webkit-input-placeholder {
        font-size: 14px;
    }
    &::-moz-placeholder {
        font-size: 14px;
    }
    & :-ms-input-placeholder {
        font-size: 14px;
    }
}



// End Codrin
