/*
 * Implementation of themes
 */
.kd-task {
  width: 100%;
  border: 1px solid #E8ECEF;
  border-radius: 4px;
  background-color: #FFFFFF;
  margin-bottom: 15px;
  overflow: hidden; }
  .kd-task:last-child {
    margin-bottom: 0; }
  .kd-task--inner {
    padding: 15px; }
  .kd-task i {
    font-size: 18px; }
  .kd-task--project-title {
    font-size: 16px;
    font-weight: normal;
    color: #252631;
    margin-bottom: 15px; }
  .kd-task--target, .kd-task--comments, .kd-task--duedate {
    display: flex;
    align-items: center; }
    .kd-task--target .nr, .kd-task--comments .nr, .kd-task--duedate .nr {
      padding-left: 5px;
      font-size: 13px;
      line-height: 1.5;
      color: #778CA2; }
  .kd-task .progress {
    height: 4px;
    border-radius: 0; }
